
@tailwind components;

@layer components{
  .CsectionOne {
    height: auto;
    padding: 40px 20px;
    background-color: #e8f8f9;
    width: 100%;
  }
  
  .CsectionTwo {
    height: auto;
    width: 100%;
    padding: 40px 20px;
    gap: 20px;
    background-color: #f8f8f8;
  
  }
  
  .CsectionTwo_title {
    font-size: 25px;
    color: #00054c;
  }
  
  .lg_screenp {
    padding: 50px 40px;
  }
  
  .md_screenp {
    padding: 50px 20px;
  }
  
  
  .form {
    position: relative;
    width: 100%;
    height: 3rem;
    margin-top: 20px;
  }

  .err_msg{
    position: absolute;
    top: 50px;
    font-size: 15px;
    color: red;
  }
  
  .form2 {
    position: relative;
    width: 100%;
    height: 100px;
  }
  
  .form_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #00054c;
    font-family: inherit;
    font-size: inherit;
    background: none;
    padding: 1.25rem;
  }
  
  .form_input2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #00054c;
    font-family: inherit;
    font-size: inherit;
    background: none;
    padding: 1.25rem;
    resize: none;
    outline: none;
  }
  
  .form_input:hover {
    border-color: #00054c;
  }
  
  .form_input:focus {
    border-color: #00054c;
    border: 1px solid #00054c;
    outline: none;
  }
  
  .form_input2:focus {
    border-color: #00054c;
    border: 1px solid #00054c;
    outline: none;
  }
  
  
  .form_label {
    position: absolute;
    left: 1rem;
    top: 0.8rem;
    padding: 0 0.5rem;
    color: #00054c;
    cursor: pointer;
    background-color: #f8f8f8;
  }
  
  .form_input:focus ~ .form_label,
  .form_input:not(:placeholder-shown).form_input:not(:focus) ~ .form_label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
  
  .form_input2:focus ~ .form_label,
  .form_input2:not(:placeholder-shown).form_input2:not(:focus) ~ .form_label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
  
 .socials_icon {
    font-size: 30px;
  }

  /* .width60{
    width: 60%;
  }  */
  
}



