
@tailwind components;



@layer components{
  .AsectionOne {
    height: auto;
    padding: 40px 20px;
    background-color: #e8f8f9;
    width: 100%;
  }
  
  
  
  .header_hr {
    border-top: 0px;
    border-bottom: 3px solid #00054c;
    width: 100%;
  }
  
  .AsectionTwo {
    height: auto;
    width: 100%;
    padding: 40px 20px;
    background-color: #f8f8f8;
  
  }
  
  .whychoose_p {
    font-size: 25px;
    color: #00054c;
    font-family: 400;
  
  }
  
  .whychoose_p2 {
    font-size: 16px;
    color: #00054c;
    font-family: 400;
  
  }
} 




