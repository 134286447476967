
@tailwind components;


 @layer components {
  .SsectionOne {
    height: auto;
    padding: 40px 20px;
    background-color: #e8f8f9;
    width: 100%;
  }
  
  .SsectionTwo {
    height: auto;
    width: 100%;
    padding: 40px 20px;
    background-color: #f8f8f8;
  }
  
  .header_hr {
    border-top: 0px;
    border-bottom: 3px solid #00054c;
    width: 100%;
  }
  
  .lg_screenp {
    padding: 50px 40px;
  }
  
  .service_type_text {
    font-size: 18px;
    color: #00054c;
  }

  .md_screenp {
    padding: 50px 20px;
  }


@media only screen and (max-width: 640px) {

  .header_hr {
    display: none;
  }


}

}  




