@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  * {
    font-family: "Titillium Web", sans-serif;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
  }

  input:focus {
    outline: none;
  }

    /* Statistics.css */
@keyframes countUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.count-up {
  font-size: 23px;
  /* margin: 10px 0; */
  color: white;
  animation: countUp 0.5s ease-in-out;
}


  .navbar_mobile{
    position: relative;
  }

  .hover_primary:hover{
    color: #1915f6;
  }

  .color_blue{
    background-color: #00054c;
  }

  .hover_btn:hover{
    background: linear-gradient(45deg, #a5aaf7,#45edff,#c3c7fa );
    color: black;
  }

  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
    padding-inline: 1rem;
  }
  
  .success-msg {
    margin-top: 5rem;
  }
  
  .success-msg img {
    margin-bottom: 1rem;
  }
  
  .success-msg h2 {
    font-size: 2rem;
    line-height: 1.1;
    margin-bottom: 1rem;
  }
  
  .err-msg {
    margin-top: 4rem;
    font-size: 1.2rem;
  }
  
  .dismiss-btn {
    margin: 1rem;
  }


  .header_btn {
    color: white;
    background-color: #1915f6;
    padding: 10px 50px;
    border-radius: 26px;
    font-size: 18px;
  }

  .world_banner {
    background-image: url("./assets/images/03da6bce6267a39c3dcc226d30f5e881.jpg");
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: auto;
    width: 100%;
    padding: 50px 20px;
  }


  .header_img{
    width: 140px;
    height: 140px;
  }

  .pad100px{
    padding-top: 100px;
  }

  .font_52px {
    font-size: 52px;
  }

  .image_msize2 {
    margin: 0 auto;
  }

  .lineh42px {
    line-height: 48.67px;
  }

  .lg_screenp {
    padding: 50px 40px;
  }

  .md_screenp {
    padding: 50px 20px;
  }

  .width332px {
    width: 332px;
    height: 332px;
  }

  .lineh85px {
    line-height: 85.18px;
  }

  .img483px {
    width: 483px;
    height: 483px;
  }

  .font32px {
    font-size: 32px;
  }

  .font40px {
    font-size: 52px;
  }

  .lineh60px {
    line-height: 60.84px;
  }

  .width60{
    width: 60%;
  }


  .rating_icon{
    color: gold;
    font-size: 20px;
  }

  .world_banner_btn {
    padding: 10px 80px;
    background-color: #1915f6;
    border-radius: 24px;
    color: white;

  }

  .world_banner_btn2 {
    padding: 10px 100px;
    background-color: #1915f6;
    border-radius: 24px;
    color: white;
    width: 80%;
  }

  .world_banner_Sbtn {
    padding: 10px 80px;
    background-color: #1915f6;
    border-radius: 24px;
    color: white;
  }

  .rate_calc_btn {
    padding: 10px 100px;
    background-color: #1915f6;
    border-radius: 24px;
    color: white;
  
  }

  .world_stats {
    margin-top: 100px;
    color: white;
    font-size: 15px;
    background-color: linear-gradient(to right, #a5aaf7, #45edff #c3c7fa);
    background-image: url("./assets/images/gradient file.png");
    background-size: cover;
    background-position: center bottom;
    padding: 20px;
    width: auto;
    height: 100px;
  }

  @keyframes animateStats {
    from {
      transform: scale(0.2);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
/* 
  @keyframes animateText {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  } */
  
  @keyframes animateFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes animateFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /* .hidden{
    display: none;
  } */
/* 
  .animate_text {
    animation: animateText 2s forwards;
  } */
  
  .animate_from_left {
    animation: animateFromLeft 2s forwards;
  }

  .animate_from_right {
    animation: animateFromRight 2s forwards;
  }
  
  .animate_stats {
    animation: animateStats 2s forwards;
  }

  .sectionOne {
    height: auto;
    width: 100%;
    background-color: #e8f8f9;
    padding: 50px 20px;
  }

  .sectionTwo {
    height: auto;
    width: 100%;
    background-color: #f8f8f8;
    padding: 50px 20px;
  }

  .font32px {
    font-size: 32px;
  }

  .text_primary {
    color: #00054c;
  }

  .img200px {
    height: 200px;
    width: 200px;
  }

  .imgCont1 {
    display: flex;
    justify-content: right;
  }

  .imgCont2 {
    display: flex;
    justify-content: left;
  }

  .service_type_title {
    font-size: 20px;
    font-weight: 600;
  }

  .service_type_text {
    font-size: 17px;
    font-weight: 400;
    color: #00054c;
    margin-top: 10px;
  }

  .sectionThree {
    height: auto;
    width: 100%;
    background-color: #e8f8f9;
    padding: 40px 20px;
  }

  .sectionFour {
    height: auto;
    width: 100%;
    background-color: #f8f8f8;
    padding: 40px 20px;
  }

  .sectionFive {
    min-height: auto;
    height: auto;
    width: 100%;
    background-color: #e8f8f9;
    padding: 40px 20px;
  }

  .menu_icon {
    display: none;
    cursor: pointer;
  }


  .Mobile_dropdown {
    position: absolute;
    top: 25%;
    left: 75%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #00054c;
    padding: 50px;
    height: 300px;
    width: 200px;
    text-align: center;
  }

  .modal,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
  }

  body.active-modal {
    overflow-y: hidden;
  }

  .overlay {
    background: #00054c67;
  }

  .cancle_icon {
    margin: 0 auto;
    font-size: 30px;
    color: white;
    margin-top: 30px;
    cursor: pointer;
  }

  .mobile_footer_ul p {
    font-size: 18px;
  }

  .copyright {
    font-size: 18px;
  }

  .footer_grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }

  .footer_style {
    padding: 48px 20px;
    padding-bottom: 0px;
  }

  .footer_header {
    font-size: 20px;
  }

  .footer_ptag {
    font-size: 15px;
  }

  .justifycenter_tablet {
    text-align: center;
  }

  .mobile_footer_ul p {
    font-size: 15px;
  }

  .copyright {
    font-size: 15px;
  }

  .email_inpt{
    padding: 15px;
    border-radius: 32px;
  }

  .footer_btn{
    padding: 15px 100px;
    background-color: #1915f6;
    border-radius: 32px;
    color: white;
    font-size: 15px;

  }

  .footer_logo{
    width: 140px;
    height: 140px;
  }

  .bg_color{
    background-color: theme('colors.primary_color');
  }

  .select_top{
    width: 100%;
    background-color: #FFFFFF;
    height: 70px;


  }

  .crypto_calc_dropdown{
    overflow: scroll;
    max-height: 400px;
    width: 100%;
    background-color: #FFFFFF;
  
  }

  .icon_cont{
    width: 40px;
    height: 40px;
  }

  .image_cont{
    width: 40px;
    height: 40px;

  }

  .image_cont img{
    width: 100%;
    height: 100%;
  }

  .icon_cont img{
    width: 100%;
    height: 100%;
  }

  .modal-exit-active {
    transition:  0.5s;
  }

  .swap_icon{
    font-size: 30px;
    margin: 0 auto;
  }

  .hover_option:hover{
    background-color: #f3f3f3;
  }

  .hideItem{
    display: none;
  }

  .calc_inpt_style{
display: flex;
    padding: 10px;
    align-items: center;
  }



  .crypto_inpt_cont input{
    background-color: #E7E6E6;
    padding: 20px 10px;
    text-align: center;
    /* border: 1px solid black; */
    } 



  @media (min-width: 768px) and (max-width: 1023px) {
    .navbar_items button {
      display: none;
    }

    .navbar_items_ul li {
      display: none;
    }

    .navbar_mobile {
      position: relative;
      
    }
  

    .menu_icon {
      display: flex;
      font-size: 30px;
      color: white;
    }

    .Mobile_dropdown {
      position: absolute;
      top: 18%;
      left: 87%;
      transform: translate(-50%, -50%);
      line-height: 1.4;
      background: #00054c;
      padding: 50px;
      height: 300px;
      width: 200px;
      text-align: center;
    }

    .world_banner_btn2 {
      padding: 10px 80px;
      /* width: 100%; */
      background-color: #1915f6;
      border-radius: 24px;
      color: white;
    }

  

    .world_banner_btn {
      width: 50%;
      margin-top: 50px;
    }

    .copyright {
      font-size: 15;
    }

    .footer_line{
      display: none;
    }
/* 
    .animate_from_left {
      animation: animateFromLeft 2s forwards;
    }
  
    .animate_from_right {
      animation: animateFromRight 2s forwards;
    }

    @keyframes animateFromLeft {
      from {
        transform: translateX(100%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    }
  
    @keyframes animateFromRight {
      from {
        transform: translateX(-100%);
        opacity: 0;
      }
      to {
        transform: translateX(0);
        opacity: 1;
      }
    } */
  }

  @media only screen and (max-width: 640px) {
    .navbar_items button {
      display: none;
    }

    .navbar_mobile {
      position: relative;
      
    }
  
    .navbar_items_ul li {
      display: none;
    }

    .menu_icon {
      display: block;
      font-size: 30px;
      color: white;
    }

    .footer_line{
      display: none;
    }
  }
}
